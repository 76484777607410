<template>
  <div class="action_wrap m_b5">
    <p class="s_success">
      {{ successMessageLable }}<span><i class="fas fa-times msg_remove"></i></span>
    </p>
  </div>
</template>

<script>
import $ from "jquery";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "Success Message",
  props: ["message"],
  methods: {
    ...mapActions("common", ["setSpinnerStatus", "setSucccessMessageStatus"]),
  },
  computed: {
    ...mapState("common", ["successMessageLable"]),
  },
  mounted() {
    let vueThis = this;
    $(document).on("click", ".msg_remove", function (e) {
      var $el = $(e.currentTarget);
      var $row = $el.closest(".action_wrap");
      $row.fadeOut(300, function () {
        vueThis.setSucccessMessageStatus(true);
        $row.remove();
      });
    });
    $(document).ready(function () {
      setTimeout(function () {
        var $row = $(".action_wrap");
        $row.fadeOut(300, function () {
          $row.remove();
        });
        vueThis.setSucccessMessageStatus(true);
      }, 7000);
    });
  },
};
</script>
